import { Autoplay, Pagination } from 'swiper';

import { SwiperOptionsType } from './models.d';

export const parallaxSpeedItems = {
  'gallery-overlay': [
    [30, -40],
    [-36, 40],
    [36, -50],
    [-24, 30],
    [4, -6],
  ],
  gallery: [
    [-10, 8],
    [-20, 16],
    [12, -8],
    [-12, 8],
    [-28, 24],
  ],
  'gallery-case-study': [
    [7, -6],
    [-12, 10],
    [10, -8],
    [-4, 2],
    [2, -4],
  ],
};

export const swiperVariantsOptions: SwiperOptionsType = {
  'gallery-row': {
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 8,
    speed: 1000,
    freeMode: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    loop: true,
    grabCursor: true,
    modules: [Pagination, Autoplay],
    className: 'mySwiper',
  },
  default: {
    slidesPerView: 'auto',
    spaceBetween: 8,
    loop: true,
    grabCursor: true,
    modules: [Pagination],
    className: 'mySwiper',
  },
};
