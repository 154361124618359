import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import { breakpoint } from 'styles';

import Image from 'components/atoms/Image';
import Container from 'components/molecules/Container';

import { VariantType } from './models.d';

const { GALLERY_OVERLAY, GALLERY, GALLERY_CASE_STUDY, GALLERY_ROW } = VariantType;

export const StyledContainer = styled(Container)`
  padding: 0;
`;

export const StyledParallaxTitle = styled(Parallax)`
  z-index: var(--z-index-ground);
  max-width: 450px;

  ${breakpoint.md} {
    max-width: 540px;
  }

  ${breakpoint.lg} {
    max-width: 940px;
  }

  ${breakpoint.xl} {
    max-width: 1050px;
  }
`;

export const StyledPhotoGallery = styled.div`
  display: grid;

  &[data-variant='${GALLERY}'] {
    margin-bottom: var(--gap-xl);

    ${breakpoint.md} {
      margin-bottom: var(--gap-xxxxxl);
    }

    ${breakpoint.lg} {
      margin-bottom: 120px;
    }

    ${breakpoint.xl} {
      grid-template-columns: repeat(5, 400px);
      gap: var(--gap-xxxxs);
      justify-content: center;
      pointer-events: none;
    }
  }

  &[data-variant='${GALLERY_OVERLAY}'] {
    grid-template-rows: 15% 15% 4% 11% 4% 11% 4% 13% 20%;
    grid-template-columns: 2.5% 5% 41.5% 5% 5% 41%;
    margin-top: 95px;
    margin-bottom: 100px;

    ${breakpoint.md} {
      grid-template-rows: repeat(5, 20%);
      grid-template-columns: 21% 2% 18% 5% 16% 4.5% 11% 7.5% 15%;
    }

    ${breakpoint.lg} {
      margin-top: 128px;
    }

    ${breakpoint.xl} {
      margin-top: 204px;
    }

    ${breakpoint.xxl} {
      margin-top: 128px;
    }

    ${breakpoint.lg} {
      margin-top: 128px;
    }

    ${breakpoint.xl} {
      margin-top: 204px;
    }

    ${breakpoint.xxl} {
      margin-top: 128px;
    }
  }

  &[data-variant='${GALLERY_CASE_STUDY}'] {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xxxxs);

    ${breakpoint.sm} {
      flex-direction: row;
    }
  }

  &[data-variant='${GALLERY_ROW}'] {
    .swiper-wrapper {
      .swiper-slide {
        max-width: 222px;
        max-height: 222px;

        ${breakpoint.lg} {
          max-width: 310px;
          max-height: 294px;
        }

        ${breakpoint.xl} {
          max-width: 448px;
          max-height: 429px;

          &:nth-child(3n + 1) {
            max-width: 497px;
          }
        }
      }
    }
  }

  &[data-variant='${GALLERY_CASE_STUDY}'],
  &[data-variant='${GALLERY}'] {
    .swiper-slide {
      width: auto;
    }
  }

  &[data-variant='${GALLERY_OVERLAY}'],
  &[data-variant='${GALLERY_ROW}'] {
    overflow: hidden;
  }

  .swiper {
    overflow: visible;
  }
`;

export const StyledParallax = styled(Parallax)`
  width: 100%;
  height: 100%;

  &[data-variant='${GALLERY}'] {
    max-width: 220px;
    max-height: 240px;
    overflow: hidden;

    ${breakpoint.md} {
      max-width: 400px;
      max-height: 429px;
    }
  }

  &[data-variant='${GALLERY_OVERLAY}'] {
    ${breakpoint.md} {
      max-width: 204px;
      max-height: 191px;
    }

    ${breakpoint.lg} {
      max-width: 312px;
      max-height: 270px;
    }

    ${breakpoint.xl} {
      max-width: 390px;
      max-height: 336px;
    }

    ${breakpoint.xxl} {
      max-width: 490px;
      max-height: 430px;
    }

    &[data-type='image-1'] {
      position: relative;
      z-index: var(--z-index-ground);
      grid-row: 4 / 7;
      grid-column: 3 / 5;
      order: 3;

      ${breakpoint.md} {
        grid-row: 2 / 5;
        grid-column: 1 / 3;
      }
    }

    &[data-type='image-2'] {
      grid-row: 2 / 5;
      grid-column: 4 / 7;
      order: 2;

      ${breakpoint.md} {
        grid-row: 1 / 4;
        grid-column: 2 / 5;
      }
    }

    &[data-type='image-3'] {
      position: relative;
      z-index: var(--z-index-two);
      grid-row: 1 / 3;
      grid-column: 2 / 5;
      order: 1;

      ${breakpoint.md} {
        grid-row: 3 / 6;
        grid-column: 4 / 7;
      }
    }

    &[data-type='image-4'] {
      position: relative;
      z-index: var(--z-index-zero);
      grid-row: 6 / 9;
      grid-column: 4 / 7;

      ${breakpoint.md} {
        grid-row: 1 / 4;
        grid-column: 6 / 9;
      }
    }

    &[data-type='image-5'] {
      position: relative;
      z-index: var(--z-index-two);
      grid-row: 8 / 10;
      grid-column: 1 / 5;

      ${breakpoint.md} {
        grid-row: 2 / 5;
        grid-column: 8 / 10;
      }
    }
  }

  &[data-variant='${GALLERY_ROW}'] {
    height: 100%;
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`;
