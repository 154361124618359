import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { SwiperProps } from 'swiper/react';

import { ImageType } from 'components/atoms/Image';

type SwiperOptionsType = {
  'gallery-row': SwiperProps;
  default: SwiperProps;
};

export enum VariantType {
  GALLERY = 'gallery',
  GALLERY_OVERLAY = 'gallery-overlay',
  GALLERY_CASE_STUDY = 'gallery-case-study',
  GALLERY_ROW = 'gallery-row',
}

export interface PhotoGalleryProps {
  variant: VariantType;
  carouselImages: ImageType[];
  description?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
}
