import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ParallaxProvider, useParallaxController } from 'react-scroll-parallax';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { MARKS } from '@contentful/rich-text-types';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperArrow from 'components/atoms/SwiperArrow';
import Typography from 'components/atoms/Typography';
import Container from 'components/molecules/Container';

import { isBrowser } from 'utils/browser';
import { useScreenService } from 'hooks/useScreenService';
import { useWindowSize } from 'hooks/useWindowResize/useWindowResize';

import { parallaxSpeedItems, swiperVariantsOptions } from './PhotoGallery.constans';
import {
  StyledContainer,
  StyledImage,
  StyledParallax,
  StyledParallaxTitle,
  StyledPhotoGallery,
} from './PhotoGallery.styles';

import { PhotoGalleryProps, VariantType } from './models.d';

import 'swiper/css/bundle';

const ParallaxCache = () => {
  const parallaxController = useParallaxController();

  useLayoutEffect(() => {
    const handler = () => parallaxController?.update();
    window.addEventListener('load', handler);

    return () => {
      window.removeEventListener('load', handler);
    };
  }, [parallaxController]);

  return null;
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <Typography asEl="span" className="gradient-text">
        {text}
        <span className="text second-text">{text}</span>
      </Typography>
    ),
  },
};

const PhotoGallery: FC<PhotoGalleryProps> = ({ variant, description, carouselImages }) => {
  const { isMdUp, isXlUp, isXxl } = useScreenService();
  const [sliderWidth, setSliderWidth] = useState(0);
  const galleryWrapperRef = useRef<HTMLDivElement | null>(null!);

  const windowSize = useWindowSize();

  useEffect(() => {
    if (galleryWrapperRef.current && isBrowser()) {
      setSliderWidth(window.innerWidth);
    }
  }, [variant, windowSize]);

  const renderPhotoGallery = (variantType) => {
    switch (variantType) {
      case VariantType.GALLERY_OVERLAY:
      case VariantType.GALLERY_CASE_STUDY:
        return carouselImages.map(({ image, alt }, idx) => (
          <StyledParallax
            data-type={`image-${idx + 1}`}
            data-variant={variant}
            disabled={!isMdUp}
            translateY={parallaxSpeedItems[variant][idx]}
            key={`image-${idx}`}
          >
            <StyledImage image={image} alt={alt} />
          </StyledParallax>
        ));
      case VariantType.GALLERY_ROW:
        return (
          <Swiper {...swiperVariantsOptions[variant]}>
            {isXlUp ? (
              <>
                <SwiperArrow variant="left" sliderVariant={variant} sliderWidth={sliderWidth} />
                <SwiperArrow variant="right" sliderVariant={variant} sliderWidth={sliderWidth} />
              </>
            ) : null}
            {carouselImages.map(({ image, alt }, idx) => (
              <SwiperSlide key={`image-${idx}`}>
                <StyledImage image={image} alt={alt} />
              </SwiperSlide>
            ))}
          </Swiper>
        );
      default:
        return isXxl ? (
          carouselImages.map(({ image, alt }, idx) => (
            <StyledParallax
              key={`image-${idx}`}
              data-type={`image-${idx + 1}`}
              data-variant={variant}
              translateY={parallaxSpeedItems[variant][idx]}
            >
              <StyledImage image={image} alt={alt} />
            </StyledParallax>
          ))
        ) : (
          <Swiper {...swiperVariantsOptions.default}>
            {isMdUp && !isXlUp ? (
              <>
                <SwiperArrow variant="left" sliderVariant={variant} sliderWidth={sliderWidth} />
                <SwiperArrow variant="right" sliderVariant={variant} sliderWidth={sliderWidth} />
              </>
            ) : null}
            {carouselImages.map(({ image, alt }, idx) => (
              <SwiperSlide key={`image-${idx}`}>
                <StyledParallax
                  data-type={`image-${idx + 1}`}
                  data-variant={variant}
                  disabled={!isMdUp}
                  translateY={parallaxSpeedItems[variant][idx]}
                >
                  <StyledImage image={image} alt={alt} />
                </StyledParallax>
              </SwiperSlide>
            ))}
          </Swiper>
        );
    }
  };

  return (
    <ParallaxProvider>
      <ParallaxCache />
      <Container contentWidth={1280}>
        {description ? (
          <StyledParallaxTitle translateY={[-58, 50]} disabled={!isMdUp}>
            <Typography
              padding={{
                top: { base: 0, lg: 130, xl: 268 },
                bottom: { base: 80, md: 130, xl: 150 },
              }}
              size={{ base: 60, md: 72, lg: 124, xl: 156 }}
              lineHeight={1.2}
              fontWeight={700}
              color="gray-900"
            >
              {renderRichText(description, options)}
            </Typography>
          </StyledParallaxTitle>
        ) : null}
      </Container>
      <StyledContainer>
        <StyledPhotoGallery
          data-testid="photo-gallery"
          data-variant={variant}
          ref={galleryWrapperRef}
        >
          {renderPhotoGallery(variant)}
        </StyledPhotoGallery>
      </StyledContainer>
    </ParallaxProvider>
  );
};
export default PhotoGallery;
