import React from 'react';
import { useSwiper } from 'swiper/react';

import Icon from '../Icon';
import { StyledSwiperArrow } from './SwiperArrow.styles';

import { SwiperArrowProps } from './models.d';

const SwiperArrow = ({ variant, sliderVariant, sliderWidth }: SwiperArrowProps) => {
  const swiper = useSwiper();

  const leftVariant = variant === 'left';
  const rightVariant = variant === 'right';

  const handleChangeSlide = () => {
    if (sliderVariant === 'right-left') {
      if (leftVariant) {
        swiper.slideNext();
      } else {
        swiper.slidePrev();
      }
    } else if (leftVariant) {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  return (
    <StyledSwiperArrow
      sliderWidth={sliderWidth}
      data-slider-variant={sliderVariant}
      data-variant={variant}
      className={`swiper-${variant}`}
      type="button"
      onClick={handleChangeSlide}
      aria-label={variant}
    >
      {leftVariant ? <Icon name="arrow-left" className="swiper-arrow" renderComponent /> : null}

      {rightVariant ? <Icon name="arrow-right" className="swiper-arrow" renderComponent /> : null}
    </StyledSwiperArrow>
  );
};
export default SwiperArrow;
