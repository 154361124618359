import styled from 'styled-components';

import { SwiperArrowProps } from '.';

export const StyledSwiperArrow = styled.button<Pick<SwiperArrowProps, 'sliderWidth'>>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-three);
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ sliderWidth }) => sliderWidth / 2}px;
  height: 100%;

  &[data-variant='left'] {
    justify-content: flex-start;
  }

  &[data-variant='right'] {
    left: ${({ sliderWidth }) => sliderWidth / 2}px;
    justify-content: flex-end;
    width: ${({ sliderWidth }) => sliderWidth / 2 - 10}px;
  }

  &[data-slider-variant='right-left'] {
    right: 0;
    left: auto;

    &[data-variant='left'] {
      right: ${({ sliderWidth }) => sliderWidth / 2}px;
    }
  }

  .swiper-arrow {
    display: none;
    width: 100px;
    height: 100px;
    padding: var(--gap-xxxs);
    margin: 0 var(--gap-xxxxxxl);
    border: 4px solid var(--c-white);
    border-radius: 50%;
  }

  &:focus-visible {
    outline: none;

    .swiper-arrow {
      display: block;
      outline: 2px solid var(--focus-color);
      outline-offset: 4px;
    }
  }
`;
